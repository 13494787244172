import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import dayjs from "dayjs";
import { DayModifiers, DayPicker } from "react-day-picker";
import styles from "./DateRange.scss";
import { Button, Dropdown } from "..";
import { useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import calendarIcon from "~assets/calendar.svg";
import { Navbar } from "./Navbar";
import { useCloseWithEsc } from "~hooks";
import { sv } from "date-fns/locale";

interface IDateProps {
  title: string;
  updateFilter: () => void;
}

interface IDateRangeFormProps {
  closeAction: () => void;
  applyAction: () => void;
}

const DateRangeForm = observer(({ closeAction, applyAction }: IDateRangeFormProps) => {
  const { transactionStore } = useStores();
  const {
    filterStart,
    setFilterStart,
    setStartDate,
    filterEnd,
    setFilterEnd,
    setEndDate,
    dateRangePresets,
    setFilterDateFromPreset,
    dateRangeLabel,
  } = transactionStore;
  const [showStart, setShowStart] = useState(false);
  const [showEnd, setShowEnd] = useState(false);

  const apply = () => {
    if (setStartDate && filterStart) {
      setStartDate(filterStart);
    }
    if (setEndDate && filterEnd) {
      setEndDate(filterEnd);
    }
    applyAction();
    closeAction();
  };

  const handleStartDayClick = (date: Date, modifiers: DayModifiers) => {
    if (modifiers.disabled) {
      return;
    }
    if (date) setFilterStart(date);
    setShowStart(false);
    setShowEnd(true);
  };

  const handleEndDayClick = (date: Date, modifiers: DayModifiers) => {
    if (modifiers.disabled) {
      return;
    }
    if (date) setFilterEnd(date);
    setShowEnd(false);
  };

  const dropdownOpenAction = () => {
    setShowStart(false);
    setShowEnd(false);
  };

  const startDayPickerClasses = [styles.dayPicker];

  if (showStart) {
    startDayPickerClasses.push(styles.open);
  }

  const endDayPickerClasses = [styles.dayPicker];

  if (showEnd) {
    endDayPickerClasses.push(styles.open);
  }

  return (
    <>
      <div className={styles.inputs}>
        <div className={styles.dateWrapper}>
          <span>{tx("misc.dateFrom")}</span>
          <Button
            title={(filterStart && dayjs(filterStart)?.format("YYYY-MM-DD")) || tx("misc.selectDate")}
            onClick={() => {
              setShowStart(true);
              setShowEnd(false);
            }}
            active={showStart}
            iconSuffix={calendarIcon}
            borderColor="blue"
            bordered
            fullWidth
            justifySpaceBetween
          />
        </div>

        <div className={styles.dateWrapper}>
          <span>{tx("misc.dateTo")}</span>
          <Button
            title={(filterEnd && dayjs(filterEnd)?.format("YYYY-MM-DD")) || tx("misc.selectDate")}
            onClick={() => {
              setShowEnd(true);
              setShowStart(false);
            }}
            active={showEnd}
            iconSuffix={calendarIcon}
            borderColor="blue"
            bordered
            fullWidth
            justifySpaceBetween
          />
        </div>
      </div>
      <div className={startDayPickerClasses.join(" ")}>
        <div>
          <DayPicker
            components={{
              Caption: (props: any) => <Navbar {...props}  />
            }}
            onDayClick={handleStartDayClick}
            defaultMonth={filterStart}
            selected={filterStart}
            disabled={
              filterEnd && {
                before: dayjs(filterEnd).subtract(1, "year").toDate(),
                after: filterEnd,
              }
            }
            locale={sv}
          />
        </div>

      </div>
      <div className={endDayPickerClasses.join(" ")}>
        <div>
          <DayPicker
            components={{
              Caption: (props: any) => <Navbar {...props} />
            }}
            onDayClick={handleEndDayClick}
            defaultMonth={filterStart}
            selected={filterEnd}
            disabled={
              filterStart && {
                before: filterStart,
                after: dayjs(filterStart).add(1, "year").toDate(),
              }
            }
            locale={sv}
          />
        </div>

      </div>

      <div className={styles.datePresets}>
        <span>{tx("date.periodOfTime")}</span>
        <Dropdown
          value={dateRangeLabel || tx("misc.select")}
          list={dateRangePresets}
          onChange={setFilterDateFromPreset}
          onOpen={dropdownOpenAction}
          innerLabel={tx("misc.select")}
        />
      </div>
      <div className={styles.actions}>
        <Button title={tx("misc.cancel")} onClick={closeAction} bordered color="black" borderColor="black" />
        <Button title={tx("misc.apply")} onClick={apply} color="red" />
      </div>
    </>
  );
});

export const DateRange = observer(({ title, updateFilter }: IDateProps) => {
  const { uiStore, transactionStore } = useStores();

  const [open, setOpen] = useState(false);
  const [popupId, setPopupId] = useState(-1);
  const ref = useRef(null);

  useEffect(() => {
    if (open) {
      if (popupId === -1) {
        setPopupId(
          uiStore.setPopup({
            children: <DateRangeForm closeAction={() => setOpen(!open)} applyAction={updateFilter} />,
            open,
            cancelAction: () => setOpen(!open),
            element: ref,
          })
        );
      }
    } else if (popupId !== -1) {
      uiStore.removePopup(popupId);
      setPopupId(-1);
    }
  }, [open]);

  useCloseWithEsc(() => setOpen(false));

  const selected = transactionStore.startDate && transactionStore.endDate;

  return (
    <div className={styles.wrapper}>
      <Button title={title} onClick={() => setOpen(!open)} bordered color={open || selected ? "blue" : ""} ref={ref} />
    </div>
  );
});
