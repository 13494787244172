import { IAccountTransactions } from "../../../../models/Engagements";

export const mockTransactionsIndex: IAccountTransactions = {
  transactions: [
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2019-03-05T00:00:00+01:00",
      valueDate: "2019-04-01T00:00:00+02:00",
      storeName: "Test Store",
      transactionAmount: 145.0,
      isReserved: true,
      transactionText: "FÖRSENINGSAVGIFT",
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2019-03-05T00:00:00+01:00",
      valueDate: "2019-04-01T00:00:00+02:00",
      storeName: "TRANSACTIONSTORE:3855592039610",
      transactionAmount: 60.0,
      isReserved: false,
      transactionText: "SKRIFTLIG PÅMIN.AVG",
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2019-03-05T00:00:00+01:00",
      valueDate: "2019-04-01T00:00:00+02:00",
      storeName: "Test Store",
      transactionAmount: 30.0,
      isReserved: false,
      transactionText: "AVIAVGIFT",
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2019-02-05T00:00:00+01:00",
      valueDate: "2019-03-01T00:00:00+01:00",
      storeName: "Test Store",
      transactionAmount: 30.0,
      isReserved: false,
      transactionText: "AVIAVGIFT",
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2017-08-07T00:00:00+02:00",
      valueDate: "2017-09-01T00:00:00+02:00",
      storeName: "Test Store",
      transactionAmount: 30.0,
      isReserved: false,
      transactionText: "AVIAVGIFT",
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2017-07-28T00:00:00+02:00",
      valueDate: "2017-07-28T00:00:00+02:00",
      storeName: "Test Store",
      transactionAmount: -200.0,
      isReserved: false,
      transactionText: "INBETALNING - PG OCR",
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2017-07-06T00:00:00+02:00",
      valueDate: "2017-08-01T00:00:00+02:00",
      storeName: "Test Store",
      transactionAmount: 30.0,
      isReserved: false,
      transactionText: "AVIAVGIFT",
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2017-06-28T00:00:00+02:00",
      valueDate: "2017-06-28T00:00:00+02:00",
      storeName: "Test Store",
      transactionAmount: -600.0,
      isReserved: false,
      transactionText: "INBETALNING - PG OCR",
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2017-06-07T00:00:00+02:00",
      valueDate: "2017-07-01T00:00:00+02:00",
      storeName: "Test Store",
      transactionAmount: 30.0,
      isReserved: false,
      transactionText: "AVIAVGIFT",
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2017-06-07T00:00:00+02:00",
      valueDate: "2018-05-31T00:00:00+02:00",
      storeName: "Test Store",
      transactionAmount: 295.0,
      isReserved: false,
      transactionText: "UPPL AVGIFT",
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2017-05-16T00:00:00+02:00",
      valueDate: "2018-05-01T00:00:00+02:00",
      storeName: "Test Store",
      transactionAmount: 6994.0,
      isReserved: false,
      transactionText: "ELON",
    },
  ],
  nextToken: "e095cb78b6e983cf",
};

export const mockTransactionsSecond: IAccountTransactions = {
  transactions: [
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2017-04-16T00:00:00+02:00",
      valueDate: "2018-04-01T00:00:00+02:00",
      storeName: "Test Store",
      transactionAmount: 236.0,
      isReserved: false,
      transactionText: "MEDIAMARKT",
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2017-03-06T00:00:00+02:00",
      valueDate: "2018-03-01T00:00:00+02:00",
      storeName: "Test Store",
      transactionAmount: 758.0,
      isReserved: false,
      transactionText: "ELGIGANTEN",
    },
  ],
  nextToken: undefined,
};

export const mockLoanTransactions: IAccountTransactions = {
  transactions: [
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2019-03-05T00:00:00+01:00",
      valueDate: "2019-04-01T00:00:00+02:00",
      storeName: "",
      transactionAmount: -145.0,
      isReserved: false,
      transactionText: "FÖRSENINGSAVGIFT",
      balance: -500,
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2019-03-05T00:00:00+01:00",
      valueDate: "2019-04-01T00:00:00+02:00",
      storeName: "",
      transactionAmount: -60.0,
      isReserved: false,
      transactionText: "SKRIFTLIG PÅMIN.AVG",
      balance: -645,
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2019-02-05T00:00:00+01:00",
      valueDate: "2019-03-01T00:00:00+01:00",
      storeName: "",
      transactionAmount: -145.0,
      isReserved: false,
      transactionText: "FÖRSENINGSAVGIFT",
      balance: -800,
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2019-02-05T00:00:00+01:00",
      valueDate: "2019-03-01T00:00:00+01:00",
      storeName: "",
      transactionAmount: -60.0,
      isReserved: false,
      transactionText: "SKRIFTLIG PÅMIN.AVG",
      balance: -860,
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2018-05-30T00:00:00+02:00",
      valueDate: "2018-05-30T00:00:00+02:00",
      storeName: "",
      transactionAmount: -80000.0,
      isReserved: true,
      transactionText: "",
      balance: -80500,
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2018-04-24T00:00:00+02:00",
      valueDate: "2018-04-24T00:00:00+02:00",
      storeName: "",
      transactionAmount: -20000.0,
      isReserved: true,
      transactionText: "",
      balance: -500,
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2017-07-31T00:00:00+02:00",
      valueDate: "2017-09-01T00:00:00+02:00",
      storeName: "",
      transactionAmount: -122.15,
      isReserved: false,
      transactionText: "RÄNTA",
      balance: -500,
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2017-07-28T00:00:00+02:00",
      valueDate: "2017-07-28T00:00:00+02:00",
      storeName: "",
      transactionAmount: 1330.0,
      isReserved: false,
      transactionText: "AUTOGIRO - BG",
      balance: -500,
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2017-06-30T00:00:00+02:00",
      valueDate: "2017-08-01T00:00:00+02:00",
      storeName: "",
      transactionAmount: -122.38,
      isReserved: false,
      transactionText: "RÄNTA",
      balance: -6545,
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2017-06-29T00:00:00+02:00",
      valueDate: "2017-06-29T00:00:00+02:00",
      storeName: "",
      transactionAmount: 1330.0,
      isReserved: false,
      transactionText: "AUTOGIRO - BG",
      balance: -500,
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2017-05-31T00:00:00+02:00",
      valueDate: "2017-07-01T00:00:00+02:00",
      storeName: "",
      transactionAmount: -130.05,
      isReserved: false,
      transactionText: "RÄNTA",
      balance: -23500,
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2017-05-30T00:00:00+02:00",
      valueDate: "2017-05-30T00:00:00+02:00",
      storeName: "",
      transactionAmount: 1330.0,
      isReserved: false,
      transactionText: "AUTOGIRO - BG",
      balance: -3500,
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2017-05-02T00:00:00+02:00",
      valueDate: "2017-04-28T00:00:00+02:00",
      storeName: "",
      transactionAmount: 1330.0,
      isReserved: false,
      transactionText: "AUTOGIRO - BG",
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2017-04-30T00:00:00+02:00",
      valueDate: "2017-06-01T00:00:00+02:00",
      storeName: "",
      transactionAmount: -130.43,
      isReserved: false,
      transactionText: "RÄNTA",
      balance: -500,
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2017-03-31T00:00:00+02:00",
      valueDate: "2017-05-01T00:00:00+02:00",
      storeName: "",
      transactionAmount: -138.52,
      isReserved: false,
      transactionText: "RÄNTA",
      balance: -500,
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2017-03-30T00:00:00+02:00",
      valueDate: "2017-03-30T00:00:00+02:00",
      storeName: "",
      transactionAmount: 1330.0,
      isReserved: false,
      transactionText: "AUTOGIRO - BG",
      balance: -500,
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2017-02-28T00:00:00+01:00",
      valueDate: "2017-04-01T00:00:00+02:00",
      storeName: "",
      transactionAmount: -128.81,
      isReserved: false,
      transactionText: "RÄNTA",
      balance: -500,
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2017-02-28T00:00:00+01:00",
      valueDate: "2017-02-28T00:00:00+01:00",
      storeName: "",
      transactionAmount: 1330.0,
      isReserved: false,
      transactionText: "AUTOGIRO - BG",
      balance: -500,
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2017-01-31T00:00:00+01:00",
      valueDate: "2017-03-01T00:00:00+01:00",
      storeName: "",
      transactionAmount: -146.45,
      isReserved: false,
      transactionText: "RÄNTA",
      balance: -500,
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2017-01-30T00:00:00+01:00",
      valueDate: "2017-01-30T00:00:00+01:00",
      storeName: "",
      transactionAmount: 1330.0,
      isReserved: false,
      transactionText: "AUTOGIRO - BG",
      balance: -500,
    },
  ],
  nextToken: undefined,
};

export const mockDepositTransactions: IAccountTransactions = {
  transactions: [
    // Reserved
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2023-04-25T00:00:00+01:00",
      valueDate: "2050-04-25T00:00:00+01:00",
      storeName: "",
      transactionAmount: -3000.0,
      isReserved: true,
      transactionText: "Specialuttag",
      balance: 0.0,
      balanceDate: "2050-04-25T00:00:00+01:00",
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2019-04-25T00:00:00+01:00",
      valueDate: "2050-04-24T00:00:00+01:00",
      storeName: "",
      transactionAmount: -2000.0,
      isReserved: true,
      transactionText: "Uttag",
      balance: undefined,
      balanceDate: "2050-04-24T00:00:00+01:00",
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2020-03-06T00:00:00+01:00",
      valueDate: "2050-04-23T00:00:00+01:00",
      storeName: "",
      transactionAmount: -1000.0,
      isReserved: true,
      transactionText: "Uttag",
      balance: undefined,
      balanceDate: "2050-04-23T00:00:00+01:00",
    },

    // Once per month
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2022-04-25T00:00:00+01:00",
      valueDate: "2022-05-25T00:00:00+01:00",
      storeName: "",
      transactionAmount: 1000.0,
      isReserved: false,
      transactionText: "Insättning",
      balance: 6000.0,
      balanceDate: "2022-05-25T00:00:00+01:00",
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2022-02-25T00:00:00+01:00",
      valueDate: "2022-04-25T00:00:00+01:00",
      storeName: "",
      transactionAmount: -2500.0,
      isReserved: false,
      transactionText: "Uttag",
      balance: 5000.0,
      balanceDate: "2022-04-25T00:00:00+01:00",
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2022-02-25T00:00:00+01:00",
      valueDate: "2022-03-25T00:00:00+01:00",
      storeName: "",
      transactionAmount: 1500.0,
      isReserved: false,
      transactionText: "Insättning",
      balance: 7500,
      balanceDate: "2022-03-25T00:00:00+01:00",
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2022-04-25T00:00:00+01:00",
      valueDate: "2022-02-25T00:00:00+01:00",
      storeName: "",
      transactionAmount: 1000.0,
      isReserved: false,
      transactionText: "Insättning",
      balance: 6000.0,
      balanceDate: "2022-02-25T00:00:00+01:00",
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2022-03-25T00:00:00+01:00",
      valueDate: "2022-01-25T00:00:00+01:00",
      storeName: "",
      transactionAmount: -2500.0,
      isReserved: false,
      transactionText: "Uttag",
      balance: 5000.0,
      balanceDate: "2022-01-25T00:00:00+01:00",
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2022-02-25T00:00:00+01:00",
      valueDate: "2021-12-25T00:00:00+01:00",
      storeName: "",
      transactionAmount: 1500.0,
      isReserved: false,
      transactionText: "Insättning",
      balance: 7500,
      balanceDate: "2021-12-25T00:00:00+01:00",
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2022-04-25T00:00:00+01:00",
      valueDate: "2021-11-25T00:00:00+01:00",
      storeName: "",
      transactionAmount: 1000.0,
      isReserved: false,
      transactionText: "Insättning",
      balance: 6000.0,
      balanceDate: "2021-11-25T00:00:00+01:00",
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2022-03-25T00:00:00+01:00",
      valueDate: "2021-10-25T00:00:00+01:00",
      storeName: "",
      transactionAmount: -2500.0,
      isReserved: false,
      transactionText: "Uttag",
      balance: 5000.0,
      balanceDate: "2021-10-25T00:00:00+01:00",
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2022-02-25T00:00:00+01:00",
      valueDate: "2021-01-25T00:00:00+01:00",
      storeName: "",
      transactionAmount: 1500.0,
      isReserved: false,
      transactionText: "Insättning",
      balance: 7500,
      balanceDate: "2021-01-25T00:00:00+01:00",
    },

    // Many same day
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      // Different month on purchaseDate than valueDate
      purchaseDate: "2023-02-25T00:00:00+01:00",
      valueDate: "2023-04-25T00:00:00+01:00",
      storeName: "",
      transactionAmount: 1500.0,
      isReserved: false,
      transactionText: "Insättning",
      balance: 6000.0,
      balanceDate: "2023-04-25T00:00:00+01:00",
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      // Different month on purchaseDate than valueDate
      purchaseDate: "2023-01-24T00:00:00+01:00",
      valueDate: "2023-04-25T00:00:00+01:00",
      storeName: "",
      transactionAmount: -600.0,
      isReserved: false,
      transactionText: "Uttag",
      balance: 6000.0,
      balanceDate: "2023-04-25T00:00:00+01:00",
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2023-04-23T00:00:00+01:00",
      valueDate: "2023-04-25T00:00:00+01:00",
      storeName: "",
      transactionAmount: -5000.0,
      isReserved: false,
      transactionText: "Uttag",
      balance: 6000.0,
      balanceDate: "2023-04-25T00:00:00+01:00",
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2023-04-25T00:00:00+01:00",
      valueDate: "2023-04-25T00:00:00+01:00",
      storeName: "",
      transactionAmount: 700.0,
      isReserved: false,
      transactionText: "Insättning",
      balance: 6000.0,
      balanceDate: "2023-04-25T00:00:00+01:00",
    },

    // One per day
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2023-03-24T00:00:00+01:00",
      valueDate: "2023-03-24T00:00:00+01:00",
      storeName: "",
      transactionAmount: -100.0,
      isReserved: false,
      transactionText: "Uttag",
      balance: 9400.0,
      balanceDate: "2023-03-24T00:00:00+01:00",
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2023-03-23T00:00:00+01:00",
      valueDate: "2023-03-23T00:00:00+01:00",
      storeName: "",
      transactionAmount: -500.0,
      isReserved: false,
      transactionText: "Uttag",
      balance: 9500.0,
      balanceDate: "2023-03-23T00:00:00+01:00",
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2023-03-23T00:00:00+01:00",
      valueDate: "2023-03-22T00:00:00+01:00",
      storeName: "",
      transactionAmount: 10000.0,
      isReserved: false,
      transactionText: "Insättning",
      balance: 10000.0,
      balanceDate: "2023-03-22T00:00:00+01:00",
    },
  ],
  nextToken: undefined,
};
